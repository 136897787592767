<template>
  <div class="w-full">
    <SuccessButton
      class="mr-6"
      :disabled="!$atividades.permissoes.includes(14)"
      @func="modalCadastroCentroCusto = true"
    ></SuccessButton>
    <SideBar
      title="Cadastrar centro de custo"
      @hide="modalCadastroCentroCusto = false"
      :active="modalCadastroCentroCusto"
      size="small"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <vs-input
              class="inputx w-full"
              name="nome"
              v-validate="'required'"
              label-placeholder="Nome"
              v-model="centroCusto.nome"
            />
            <span class="text-danger text-sm" v-show="errors.has('nome')">{{
              $validators.empty
            }}</span>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  name: "ModalCadastroCentroCusto",
  data() {
    return {
      centroCusto: {},
      modalCadastroCentroCusto: false,
      error: false
    };
  },
  methods: {
    async submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.cadastrarCentroCusto();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async cadastrarCentroCusto() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.$vs.loading();
          try {
            const res = await this.$http.post("centro_custo", this.centroCusto);
            let logData = {
              id_colaborador: localStorage.getItem("id"),
              funcao: 'cadastrar',
              setor: 'financeiro',
              ip: window.localStorage.getItem("ip"),
              texto: 'Cadastro do centro de custo N°' + res.id}
            await this.$logger(logData)
            this.centroCusto = {};
            this.$vs.notify(this.$notify.Success);
            this.modalCadastroCentroCusto = false;
          } catch (err) {
            const error = this.$httpErrors(err);
            this.$vs.notify(error);
          } finally {
            this.$vs.loading.close();
            await this.$emit("update");
          }
        }
      });
    }
  },
  components: components
};
</script>

<style></style>
