<template>
  <div class="w-full">
    <vs-table
      maxHeight="68vh"
      noDataText=""
      search
      pagination
      max-items="10"
      :data="centroCusto"
    >
      <template slot="header">
        <vs-row vs-w="12">
          <vs-col vs-w="6">
            <h4 style="color: #b4aa99">Centro de custos</h4>
          </vs-col>
          <vs-col vs-w="6">
            <ModalCadastroCentroCusto @update="getCentroCusto" />
          </vs-col>
        </vs-row>
      </template>

      <template slot="thead">
        <vs-th sort-key="nome">Centro de custo</vs-th>
        <vs-th>Ações</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr
          :state="data[indextr].lixeira == 1 ? 'danger' : ''"
          :key="indextr"
          v-for="(tr, indextr) in data"
        >
          <vs-td :data="data[indextr].nome">{{ data[indextr].nome }}</vs-td>
          <vs-td class="actions">
            <ModalEditarCentroCusto
              :centroCusto="data[indextr]"
              @update="getCentroCusto"
            />
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div v-if="centroCusto.length == 0">
      <div class="con-colors">
        <ul>
          <li class="danger-box">
            <h2 class="p-5" style="color: white !important">
              Nenhum centro custo cadstrado
            </h2>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCadastroCentroCusto from "./cadastro.vue"
import ModalEditarCentroCusto from "./editar.vue"

export default {
  data () {
    return {
      centroCusto: []
    }
  },
  methods: {
    async getCentroCusto () {
      this.$vs.loading()
      try {
        this.centroCusto = await this.$http.get(`centro_custo`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        this.$vs.loading.close()
      }
    }
  },
  components: {
    ModalCadastroCentroCusto,
    ModalEditarCentroCusto
  },
  mounted () {
    this.getCentroCusto()
  }
};
</script>

<style>
.actions {
  width: 60px !important;
}
</style>
